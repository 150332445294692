<template>
  <div>
    <div class="d-flex">
      <h1 class="headline--md text-bold text-uppercase">buy cryptos orders</h1>
      <button class="btn btn--primary btn--md" @click="onExportPaymentOrders">Export as CSV</button>
    </div>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <multiple-select :value="filterValues" :options="options" @filter-callback="onGetPaymentOrdersByFilter($event)" />

      <div class="d-flex mr-16">
        <div class="search-box-group mr-8">
          <search-box v-model="searchParams.searchText" holder-text="Search" @on-search="onGetPaymentOrders" />

          <selection-box
            v-model="searchParams.searchTarget"
            :options="searchTargetOptions"
            :is-align-right="true"
            :on-check="onGetPaymentOrdersByTarget"
            selected-box-class="is-lg search-box__filter"
          />
        </div>

        <button class="btn btn--md btn--primary mr-8" @click="onGetPaymentOrders">Search</button>
      </div>

      <selection-box
        v-model="params.perPage"
        :options="pagerOptions"
        :is-align-right="true"
        :on-check="onGetPaymentOrders"
      />
    </div>

    <div
      v-if="paymentOrders && paymentOrders.data && paymentOrders.data.length"
      class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
    >
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>Bought Crypto<br />Amount</th>
            <th>Spending<br />Amount (Rp)</th>
            <th>Amount to<br />Receive (Rp)</th>
            <th>Order Time<br />UTC</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <router-link
            v-for="order in paymentOrdersSelect"
            :key="order.id"
            :to="{ name: 'PaymentOrder', params: { id: order.id } }"
            tag="tr"
            class="link--clone"
          >
            <td class="clipboard-copy">
              <span class="clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ order.user.email }}</span>
                <copy-clipboard :is-external="false" @on-copy="doCopy($event)" />
              </span>
            </td>
            <td>{{ order.amountCrypto | float_number_with_commas }}<br />TOKO</td>
            <td>{{ order.amountCurrency | float_number_with_commas }}</td>
            <td class="clipboard-copy">
              <span class="clipboard-copy__text">
                <span>{{ (order.amountCurrency + order.fee) | float_number_with_commas }}</span>
                <span class="clipboard-copy__text-inner d-hidden">{{ order.amountCurrency + order.fee }}</span>
                <copy-clipboard :is-external="false" @on-copy="doCopy($event)" />
              </span>
            </td>
            <td v-html="getMomentLTSFormatted(order.created_at_milliseconds)" />
            <td v-html="formatPaymentOrderStatus(order)" />
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ paymentOrders.total | format_order_count_text }} in total</p>

        <pagination
          v-if="isNextPage(paymentOrders.total)"
          v-model="page"
          :page-count="getPageCount(paymentOrders.totalPages, paymentOrders.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        />
      </div>
    </div>

    <p v-else class="mt-16">No data</p>

    <loading :open="!isLoaded" />

    <download-csv ref="downloadCsv" style="display: none" :data="paymentOrdersExportData || []" :name="exportFilename">
    </download-csv>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { PAYMENT_ORDER_STATUS } from '@/utils/key-mapping'
import { PAGER_OPTIONS, PAYMENT_ORDER_STATUS_OPTIONS } from '@/utils/data-sets'
import toggleLoading from '@/mixins/toggle-loading'
import pagination from '@/mixins/pagination'
import momentltsFormat from '@/mixins/momentlts-format'
import copyClipboard from '@/mixins/copy-clipboard'
import handleAssets from '@/mixins/handle-assets'
import handleFilterBox from '@/mixins/handle-filter-box'
import paymetnOrderStatus from '@/mixins/payment-order-status'
const MultipleSelect = () => import('@/components/MultipleSelect')
const CopyClipboard = () => import('@/components/CopyClipboard')
const Pagination = () => import('@/components/Pagination')
const SelectionBox = () => import('@/components/SelectionBox')
const SearchBox = () => import('@/components/SearchBox')

export default {
  data() {
    return {
      options: [
        {
          label: 'Order By',
          options: [
            { name: 'Most Recent', value: 'updated_at', orderBy: 'desc', category: 'order' },
            { name: 'Highest Speding Amount', value: 'amount_currency', orderBy: 'desc', category: 'order' },
            { name: 'Lowest Speding Amount', value: 'amount_currency', orderBy: 'asc', category: 'order' }
          ]
        },
        {
          label: 'Status Filter',
          options: PAYMENT_ORDER_STATUS_OPTIONS
        }
      ],
      filterValues: [],
      searchParams: {
        searchText: '',
        searchTarget: 1
      },
      params: {
        perPage: 10
      },
      page: 1,
      pagerOptions: PAGER_OPTIONS,
      pamentOrderStatusOptions: PAYMENT_ORDER_STATUS_OPTIONS,
      paymentOrderStatuses: PAYMENT_ORDER_STATUS,
      searchTargetOptions: [
        { value: 0, name: 'Amount Received', disabled: false },
        { value: 1, name: 'Email', disabled: false },
        { value: 2, name: 'Wallet Address', disabled: false }
      ]
    }
  },

  mixins: [
    toggleLoading,
    pagination,
    momentltsFormat,
    copyClipboard,
    handleAssets,
    handleFilterBox,
    paymetnOrderStatus
  ],

  components: {
    SearchBox,
    SelectionBox,
    Pagination,
    CopyClipboard,
    MultipleSelect
  },

  computed: {
    ...mapState('payment', ['paymentOrders', 'assets']),

    ...mapGetters('payment', ['paymentOrdersSelect', 'paymentOrdersExportData']),

    searchTarget() {
      return parseInt(this.searchParams.searchTarget)
    },

    exportFilename() {
      const today = this.$moment(new Date()).format('LTS')
      return `payment_order_(${today}).csv`
    }
  },

  methods: {
    ...mapActions('payment', ['getPaymentOrders', 'exportPaymentOrders']),

    onGetPaymentOrdersByTarget() {
      if (!this.searchParams.searchText) return
      this.onGetCrowdfundingFeeds()
    },

    onGetPaymentOrders() {
      this.toggleLoading()
      this.page = 1
      this.getPaymentOrders(this.getPaymentOrderParams()).finally(() => {
        this.toggleLoading()
      })
    },

    onGetPaymentOrdersByFilter(values) {
      this.filterValues = values
      const needReviewStatus = this.filterValues.filter(status => {
        return status.category === 'status' && status.value === this.paymentOrderStatuses.initial
      })

      if (needReviewStatus.length) {
        this.filterValues = [
          ...this.filterValues,
          { category: 'status', name: 'Pending', value: this.paymentOrderStatuses.pending },
          { category: 'status', name: 'Image uploaded', value: this.paymentOrderStatuses.imageTransferSubmited },
          { category: 'status', name: 'Expired', value: this.paymentOrderStatuses.expire }
        ]
      }
      this.onGetPaymentOrders()
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getPaymentOrders(this.getPaymentOrderParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getPaymentOrderParams() {
      const params = {
        ...this.statuses,
        page: this.page,
        limit: this.params.perPage,
        order_by: this.orderBy.orderBy,
        sort_by: this.orderBy.sortBy
      }

      if (this.searchParams.searchText) {
        if (this.searchTarget === 0) params.amount = this.searchParams.searchText
        if (this.searchTarget === 1) params.email = this.searchParams.searchText
        if (this.searchTarget === 2) params.wallet_address = this.searchParams.searchText
      }

      return params
    },

    onExportPaymentOrders() {
      this.toggleLoading()
      this.exportPaymentOrders().finally(() => {
        this.toggleLoading()
      })
    }
  },

  created() {
    this.getPaymentOrders(this.getPaymentOrderParams()).finally(() => {
      this.toggleLoading()
    })
  },

  watch: {
    paymentOrdersExportData: {
      handler() {
        setTimeout(() => {
          this.$refs.downloadCsv.generate()
        }, 0)
      },

      deep: true
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'PaymentOrder') {
      this.storeAssets('payment', 'STORE_ASSETS')
      next()
    } else {
      this.clearAssets('payment', 'CLEAR_ASSETS')
      next()
    }
  }
}
</script>
